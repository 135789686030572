export const AppName = 'Pdp'
export const ShouldUseClarityAutomate = true
export const FeatureFlags = [
  "PDP_Offer_Title_Brand_Sku",
  "PDP_Compare_Tool",
  "Add_To_List_Badge_Expanded_PDP",
  "PDP_Show_Fitment_Feedback_Internals",
  "Ribbon_Text_Dollar_Vs_Percentage",
  "FrontEnd_Engine_Fitment_UX"
]
